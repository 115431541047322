import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { Link } from "react-scroll";
import logo_claro from "../../assets/images/iconos/Logo_CB.svg";
import { scroller } from "../../helpers/scroller";

const NavBar = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [scrollNav, setScrollNav] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    window.scrollY === 0 ? setScrollNav(true) : setScrollNav(false);
  };

  const handleClick = () => {
    setNavOpen(!navOpen);
  };

  const goHome = () => {
    scroller(".carousel", 0);
  };

  const goHomeBur = () => {
    scroller(".carousel", 0);
    if (navOpen === true) {
      setNavOpen(!navOpen);
    }
  };

  const goClasica = () => {
    scroller(".linea-clasica", 100);
    setNavOpen(!navOpen);
  };

  const goModerna = () => {
    scroller(".linea-moderna", 100);
    setNavOpen(!navOpen);
  };

  const goPremium = () => {
    scroller(".linea-premium", 100);
    setNavOpen(!navOpen);
  };

  const goContacto = () => {
    scroller(".footer-container", 0);
    setNavOpen(!navOpen);
  };

  return (
    <>
      <header
        className={
          scrollNav ? `header wrapper` : `header header-scroll wrapper`
        }
      >
        <div className="wrapper">
          <div className="burger-container" onClick={handleClick}>
            <Link to="/" className="logo-link">
              <img
                loading="lazy"
                src={logo_claro}
                alt="Logo Casa Blanca"
                className="logoRes"
                onClick={goHomeBur}
              />
            </Link>
            <div
              className={
                navOpen ? "burger burger-close" : "burger burger-branding"
              }
            ></div>
          </div>
          <div
            className={navOpen ? "navbar-responsive open" : "navbar-responsive"}
          >
            <Link to="/" className="logo-link">
              <img
                loading="lazy"
                src={logo_claro}
                alt="Logo Casa Blanca"
                className="logo"
                onClick={goHome}
              />
            </Link>
            <nav className="navbar__items">
              <Link
                to="/"
                spy={true}
                smooth={true}
                offset={-70}
                duration={1000}
                className="nav-links"
              >
                MODELOS
                <link
                  rel="stylesheet"
                  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
                />
                <span
                  className={
                    window.innerWidth <= 900
                      ? "material-symbols-outlined iconDisp"
                      : "material-symbols-outlined"
                  }
                >
                  expand_more
                </span>
                <ul className="submenu">
                  <li className="submenu__item">
                    <Link onClick={goClasica} to="/">
                      Línea clásica
                    </Link>
                  </li>
                  <li className="submenu__item">
                    <Link onClick={goModerna} to="/">
                      Línea moderna
                    </Link>
                  </li>
                  <li className="submenu__item">
                    <Link onClick={goPremium} to="/">
                      Línea premium
                    </Link>
                  </li>
                </ul>
              </Link>
              <Link
                to="/"
                spy={true}
                smooth={true}
                offset={-70}
                duration={1000}
                className="nav-links"
                onClick={goContacto}
              >
                CONTACTO
              </Link>
              <div className="navbar__items__redes">
                <a
                  href="https://api.whatsapp.com/send?phone=5491139167902&text=Hola,%20quisiera%20realizar%20una%20consulta."
                  spy={true}
                  smooth={true}
                  offset={-40}
                  duration={600}
                  className="nav-links"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-whatsapp fa-lg" href=""></i>
                </a>
                <a
                  href="https://www.instagram.com/constructoracasablanca/"
                  target="_blank"
                  spy={true}
                  smooth={true}
                  offset={-40}
                  duration={600}
                  className="nav-links"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-instagram fa-lg"></i>
                </a>
                <a
                  href="https://www.facebook.com/Casa-Blanca-Constructora-1970265123289580"
                  target="_blank"
                  spy={true}
                  smooth={true}
                  offset={-40}
                  duration={600}
                  className="nav-links"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-facebook fa-lg"></i>
                </a>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default NavBar;
