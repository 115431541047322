import React, { useEffect, useState } from "react";
import "./Footer.css";
import { IoLogoWhatsapp } from "react-icons/io";
import { ImPhone } from "react-icons/im";
import { FaMapMarkerAlt } from "react-icons/fa";
import Form from "../Form/Form";
import FormSuccess from "../Form/FormSuccess";

function Footer() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [currentYear, setCurrentYear] = useState();

  function submitForm() {
    setIsSubmitted(true);
  }

  useEffect(() => {
    const today = new Date(Date.now());
    setCurrentYear(today.getFullYear());
  }, []);

  return (
    <footer className="footer">
      <div className="wrapper footer-container" id="contact">
        <div className="footer-form">
          {!isSubmitted ? <Form submitForm={submitForm} /> : <FormSuccess />}
        </div>

        <div className="footer-contacto-horarios">
          <div className="footer-contacto">
            <h2>Contacto</h2>
            <a href="tel:08008880158">
              <ImPhone className="footer_icon_social" /> 0800-888-0158
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5491139167902&text=Hola,%20quisiera%20realizar%20una%20consulta."
              target="_blank"
              rel="noreferrer"
            >
              <IoLogoWhatsapp className="footer_icon_social" /> 11-39167902
            </a>
          </div>

          <div className="footer-horarios">
            <h2>Horarios</h2>
            <a
              href="https://goo.gl/maps/3gh7edCuYfswPshp8"
              target="_blank"
              className="footer-horarios-ciudades"
              rel="noreferrer"
            >
              Buenos Aires
            </a>
            <p>
              <strong>Lunes a domingo</strong>
              <br />
              10:00 • 18:00
            </p>

            <a
              href="https://goo.gl/maps/ow96kJSEiK14sSXH6"
              target="_blank"
              className="footer-horarios-ciudades"
              rel="noreferrer"
            >
              Córdoba
            </a>
            <p>
              <strong>Lunes a viernes</strong>
              <br />
              10:00 • 18:00
            </p>
            <p>
              <strong>Sábados</strong>
              <br />
              9:00 • 15:00
            </p>

            <div className="footer-link-items__redes">
              <a
                href="https://www.instagram.com/constructoracasablanca/"
                target="_blank"
                className="footer-link"
                rel="noreferrer"
              >
                <i className="fa-brands fa-instagram fa-lg"></i>
              </a>
              <a
                href="https://www.facebook.com/Casa-Blanca-Constructora-1970265123289580"
                target="_blank"
                className="footer-link"
                rel="noreferrer"
              >
                <i className="fa-brands fa-facebook fa-lg"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-section-end wrapper">
        <small style={{ textAlign: "left" }} className="footer-horarios">
          Casa Blanca {currentYear} © Todos los derechos reservados
        </small>
      </div>
    </footer>
  );
}

export default Footer;
