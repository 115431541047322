import React from "react";
import CardItem from "./ModelItem";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./Models.css";

const MODELO1 = [
  "./images/modelos/modelo_9011/1.webp",
  "./images/modelos/modelo_9011/2.webp",
  "./images/modelos/modelo_9011/3.webp",
  "./images/modelos/modelo_9011/4.webp",
  "./images/modelos/modelo_9011/5.webp",
  "./images/modelos/modelo_9011/plano.webp",
];

const MODELO2 = [
  "./images/modelos/modelo_9021/1.webp",
  "./images/modelos/modelo_9021/2.webp",
  "./images/modelos/modelo_9021/3.webp",
  "./images/modelos/modelo_9021/4.webp",
  "./images/modelos/modelo_9021/5.webp",
  "./images/modelos/modelo_9021/plano.webp",
];

const MODELO3 = [
  "./images/modelos/modelo_9031/1.webp",
  "./images/modelos/modelo_9031/2.webp",
  "./images/modelos/modelo_9031/3.webp",
  "./images/modelos/modelo_9031/4.webp",
  "./images/modelos/modelo_9031/5.webp",
  "./images/modelos/modelo_9031/plano.webp",
];

const MODELO4 = [
  "./images/modelos/modelo_9041/1.webp",
  "./images/modelos/modelo_9041/2.webp",
  "./images/modelos/modelo_9041/3.webp",
  "./images/modelos/modelo_9041/4.webp",
  "./images/modelos/modelo_9041/5.webp",
  "./images/modelos/modelo_9041/plano.webp",
];

const MODELO5 = [
  "./images/modelos/modelo_9051/1.webp",
  "./images/modelos/modelo_9051/2.webp",
  "./images/modelos/modelo_9051/3.webp",
  "./images/modelos/modelo_9051/4.webp",
  "./images/modelos/modelo_9051/5.webp",
  "./images/modelos/modelo_9051/6.webp",
  "./images/modelos/modelo_9051/7.webp",
  "./images/modelos/modelo_9051/plano.webp",
];

const MODELO6 = [
  "./images/modelos/modelo_9061/1.webp",
  "./images/modelos/modelo_9061/2.webp",
  "./images/modelos/modelo_9061/3.webp",
  "./images/modelos/modelo_9061/4.webp",
  "./images/modelos/modelo_9061/5.webp",
  "./images/modelos/modelo_9061/6.webp",
  "./images/modelos/modelo_9061/plano.webp",
];

const MODELO7 = [
  "./images/modelos/modelo_9071/1.webp",
  "./images/modelos/modelo_9071/2.webp",
  "./images/modelos/modelo_9071/3.webp",
  "./images/modelos/modelo_9071/4.webp",
  "./images/modelos/modelo_9071/5.webp",
  "./images/modelos/modelo_9071/6.webp",
  "./images/modelos/modelo_9071/plano.webp",
];
const MODELO8 = [
  "./images/modelos/modelo_9081/1.webp",
  "./images/modelos/modelo_9081/2.webp",
  "./images/modelos/modelo_9081/3.webp",
  "./images/modelos/modelo_9081/4.webp",
  "./images/modelos/modelo_9081/5.webp",
  "./images/modelos/modelo_9081/6.webp",
  "./images/modelos/modelo_9081/7.webp",
  "./images/modelos/modelo_9081/plano.webp",
];
const MODELO9 = [
  "./images/modelos/modelo_9091/1.webp",
  "./images/modelos/modelo_9091/2.webp",
  "./images/modelos/modelo_9091/3.webp",
  "./images/modelos/modelo_9091/4.webp",
  "./images/modelos/modelo_9091/5.webp",
  "./images/modelos/modelo_9091/plano.webp",
];

const MODELO10 = [
  "./images/modelos/modelo_8011/1.webp",
  "./images/modelos/modelo_8011/2.webp",
  "./images/modelos/modelo_8011/3.webp",
  "./images/modelos/modelo_8011/4.webp",
  "./images/modelos/modelo_8011/5.webp",
  "./images/modelos/modelo_8011/6.webp",
  "./images/modelos/modelo_8011/plano.webp",
];

const MODELO11 = [
  "./images/modelos/modelo_8021/1.webp",
  "./images/modelos/modelo_8021/2.webp",
  "./images/modelos/modelo_8021/3.webp",
  "./images/modelos/modelo_8021/4.webp",
  "./images/modelos/modelo_8021/plano.webp",
];

const MODELO12 = [
  "./images/modelos/modelo_8031/1.webp",
  "./images/modelos/modelo_8031/2.webp",
  "./images/modelos/modelo_8031/3.webp",
  "./images/modelos/modelo_8031/4.webp",
  "./images/modelos/modelo_8031/5.webp",
  "./images/modelos/modelo_8031/6.webp",
  "./images/modelos/modelo_8031/7.webp",
  "./images/modelos/modelo_8031/8.webp",
  "./images/modelos/modelo_8031/9.webp",
  "./images/modelos/modelo_8031/plano.webp",
];

const MODELO13 = [
  "./images/modelos/modelo_8041/1.webp",
  "./images/modelos/modelo_8041/2.webp",
  "./images/modelos/modelo_8041/3.webp",
  "./images/modelos/modelo_8041/4.webp",
  "./images/modelos/modelo_8041/5.webp",
  "./images/modelos/modelo_8041/plano.webp",
];

const MODELO14 = [
  "./images/modelos/modelo_8051/1.webp",
  "./images/modelos/modelo_8051/2.webp",
  "./images/modelos/modelo_8051/3.webp",
  "./images/modelos/modelo_8051/4.webp",
  "./images/modelos/modelo_8051/5.webp",
  "./images/modelos/modelo_8051/6.webp",
  "./images/modelos/modelo_8051/7.webp",
  "./images/modelos/modelo_8051/plano.webp",
];

const MODELO15 = [
  "./images/modelos/modelo_8061/1.webp",
  "./images/modelos/modelo_8061/2.webp",
  "./images/modelos/modelo_8061/3.webp",
  "./images/modelos/modelo_8061/4.webp",
  "./images/modelos/modelo_8061/5.webp",
  "./images/modelos/modelo_8061/6.webp",
  "./images/modelos/modelo_8061/7.webp",
  "./images/modelos/modelo_8061/plano.webp",
];

const items_1 = [
  {
    src: "./images/modelos/modelo_9011/1.webp",
    text: "",
    label: "Modelo 9011",
    path: "/Services",
    camas: "2",
    duchas: "1",
    metros_cubierto: "70.23",
    metros_semicubierto: "11.85",
    entrega: "30",
    cuotas: "48",
    plantas: "1",
    video: true,
    zoom: false,
    vsrc: "./images/modelos/modelo_9011/v_9011.mp4",
    contenedor: MODELO1,
  },
  {
    src: "./images/modelos/modelo_9021/1.webp",
    text: "",
    label: "Modelo 9021",
    path: "/Services",
    camas: "2",
    duchas: "1",
    metros_cubierto: "63.45",
    metros_semicubierto: "24.30",
    entrega: "30",
    cuotas: "48",
    plantas: "1",
    video: false,
    zoom: true,
    vsrc: "",
    contenedor: MODELO2,
  },
  {
    src: "./images/modelos/modelo_9031/1.webp",
    text: "",
    label: "Modelo 9031",
    path: "/Services",
    camas: "2",
    duchas: "1",
    metros_cubierto: "65.54",
    metros_semicubierto: "23.99",
    entrega: "30",
    cuotas: "48",
    plantas: "1",
    video: true,
    zoom: false,
    vsrc: "./images/modelos/modelo_9031/v_9031.mp4",
    contenedor: MODELO3,
  },
];

const items_2 = [
  {
    src: "./images/modelos/modelo_9041/1.webp",
    text: "",
    label: "Modelo 9041",
    path: "/Services",
    camas: "2",
    duchas: "2",
    metros_cubierto: "108.37",
    metros_semicubierto: "16.50",
    entrega: "30",
    cuotas: "84",
    plantas: "2",
    video: true,
    zoom: false,
    vsrc: "./images/modelos/modelo_9041/v_9041.mp4",
    contenedor: MODELO4,
  },
  {
    src: "./images/modelos/modelo_9051/1.webp",
    text: "",
    label: "Modelo 9051",
    path: "/Services",
    camas: "2",
    duchas: "3",
    metros_cubierto: "103.15",
    metros_semicubierto: "13.22",
    entrega: "30",
    cuotas: "96",
    plantas: "2",
    video: true,
    zoom: false,
    vsrc: "./images/modelos/modelo_9051/v_9051.mp4",
    contenedor: MODELO5,
  },
  {
    src: "./images/modelos/modelo_9061/1.webp",
    text: "",
    label: "Modelo 9061",
    path: "/Services",
    camas: "2",
    duchas: "3",
    metros_cubierto: "110.07",
    metros_semicubierto: "13.33",
    entrega: "30",
    cuotas: "102",
    plantas: "2",
    video: false,
    zoom: true,
    vsrc: "",
    contenedor: MODELO6,
  },
];

const items_3 = [
  {
    src: "./images/modelos/modelo_9071/1.webp",
    text: "",
    label: "Modelo 9071",
    path: "/Services",
    camas: "3",
    duchas: "3",
    metros_cubierto: "91.77",
    metros_semicubierto: "9.21",
    entrega: "30",
    cuotas: "72",
    plantas: "2",
    video: false,
    zoom: true,
    vsrc: "",
    contenedor: MODELO7,
  },

  {
    src: "./images/modelos/modelo_9081/1.webp",
    text: "",
    label: "Modelo 9081",
    path: "/Services",
    camas: "2",
    duchas: "2",
    metros_cubierto: "138.74",
    metros_semicubierto: "11.26",
    entrega: "30",
    cuotas: "120",
    plantas: "2",
    video: true,
    zoom: false,
    vsrc: "./images/modelos/modelo_9081/v_9081.mp4",
    contenedor: MODELO8,
  },
  {
    src: "./images/modelos/modelo_9091/1.webp",
    text: "",
    label: "Modelo 9091",
    path: "/Services",
    camas: "3",
    duchas: "2",
    metros_cubierto: "121.55",
    metros_semicubierto: "28.45",
    entrega: "30",
    cuotas: "114",
    plantas: "2",
    video: true,
    zoom: false,
    vsrc: "./images/modelos/modelo_9091/v_9091.mp4",
    contenedor: MODELO9,
  },
];

const items_4 = [
  {
    src: "./images/modelos/modelo_8011/1.webp",
    text: "",
    label: "Modelo 8011",
    path: "/Services",
    camas: "1",
    duchas: "1",
    metros_cubierto: "40.38",
    metros_semicubierto: "3.18",
    entrega: "60",
    cuotas: "36",
    plantas: "2",
    video: false,
    zoom: true,
    vsrc: "",
    tag: "Promo Imperdible",
    contenedor: MODELO10,
  },

  {
    src: "./images/modelos/modelo_8021/1.webp",
    text: "",
    label: "Modelo 8021",
    path: "/Services",
    camas: "2",
    duchas: "1",
    metros_cubierto: "67.09",
    metros_semicubierto: "6.36",
    entrega: "35",
    cuotas: "60",
    plantas: "2",
    video: true,
    zoom: false,
    vsrc: "./images/modelos/modelo_8021/v_8021.mp4",
    tag: "Precio Imperdible",
    contenedor: MODELO11,
  },
  {
    src: "./images/modelos/modelo_8031/1.webp",
    text: "",
    label: "Modelo 8031",
    path: "/Services",
    camas: "3",
    duchas: "2",
    metros_cubierto: "75.69",
    metros_semicubierto: "6.36",
    entrega: "30",
    cuotas: "66",
    plantas: "2",
    video: true,
    zoom: false,
    vsrc: "./images/modelos/modelo_8031/v_8031.mp4",
    tag: "Oferta Limitada",
    contenedor: MODELO12,
  },
  {
    src: "./images/modelos/modelo_8041/1.webp",
    text: "",
    label: "Modelo 8041",
    path: "/Services",
    camas: "1",
    duchas: "1",
    metros_cubierto: "36",
    metros_semicubierto: "14",
    entrega: "55",
    cuotas: "36",
    plantas: "1",
    video: true,
    zoom: false,
    vsrc: "./images/modelos/modelo_8041/v_8041.mp4",
    contenedor: MODELO13,
  },
  {
    src: "./images/modelos/modelo_8051/1.webp",
    text: "",
    label: "Modelo 8051",
    path: "/Services",
    camas: "1",
    duchas: "1",
    metros_cubierto: "50.8",
    metros_semicubierto: "9.2",
    entrega: "45",
    cuotas: "48",
    plantas: "1",
    video: true,
    zoom: false,
    vsrc: "./images/modelos/modelo_8051/v_8051.mp4",
    contenedor: MODELO14,
  },
  {
    src: "./images/modelos/modelo_8061/1.webp",
    text: "",
    label: "Modelo 8061",
    path: "/Services",
    camas: "2",
    duchas: "1",
    metros_cubierto: "65.9",
    metros_semicubierto: "14.1",
    entrega: "35",
    cuotas: "60",
    plantas: "1",
    video: false,
    zoom: true,
    vsrc: "./images/modelos/modelo_9091/v_9091.mp4",
    contenedor: MODELO15,
  },
];

function Models() {
  return (
    <div className="cards" id="models">
      <div className="cards__container wrapper">
        {/* <div className="wrapper"> */}
        <div style={{ position: "relative" }}>
          <img
            className="oferta-lanzamiento"
            src="/Oferta_Lanzamiento.svg"
            alt=""
          />
          <h2
            style={{ fontWeight: "500" }}
            className="cardLine mt linea-cabana"
          >
            NUEVA LÍNEA DE CABAÑAS
            <div className="rectangulo rectangulo-cabana"></div>
          </h2>
        </div>
        <p className="cardLineSubText cardLineSubText-cabana">
          ¡Construí tu propia Cabaña en sólo 90 días!
        </p>
        <ul className="cards__items cabanas-items">
          {items_4.map((item, i) => (
            <CardItem
              key={i}
              src={item.src}
              text={item.text}
              label={item.label}
              path={item.path}
              camas={item.camas}
              duchas={item.duchas}
              metros_cubierto={item.metros_cubierto}
              metros_semicubierto={item.metros_semicubierto}
              entrega={item.entrega}
              cuotas={item.cuotas}
              plantas={item.plantas}
              video={item.video}
              tag={item.tag}
              zoom={item.zoom}
              vsrc={item.vsrc}
              contenedor={item.contenedor}
            />
          ))}
        </ul>
        <div style={{ position: "relative", marginBottom: "100px" }}>
          <img
            style={{ position: "absolute", top: "-80px", left: "-40px" }}
            src="/Complejo personalizado.svg"
            alt=""
          />
          <div className="contenedor-complejo">
            <img width={"100%"} src="/Complejo.webp" alt="Complejo" />
            <div className="contenedor-complejo__enunciado">
              <h1>Tu complejo de cabañas</h1>
              <p>
                Podes diseñar tu complejo con la cantidad de cabañas y los
                modelos que vos quieras.
              </p>
              <div className="contenedor-complejo__enunciado__box">
                <p>
                  ¡Aprovechá la oportunidad de tener{" "}
                  <strong>un ingreso fijo con la mínima inversión!</strong>
                </p>
              </div>
              <div className="items__complejo">
                <div
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                    rowGap: "6px",
                  }}
                  className="cards__item__prince"
                >
                  <div className="cards__item__prince__item">
                    <img
                      src="/Icono_Compra_Protegida.svg"
                      alt="Compra protegida"
                    />
                    <span style={{ fontSize: "9px" }}>Compra protegida.</span>
                  </div>
                  <div className="cards__item__prince__item">
                    <img src="/Icono_Garantía.svg" alt="Compra protegida" />
                    <span style={{ fontSize: "9px", width: "70%" }}>
                      Entrega garantizada a partir de los 90 días.
                    </span>
                  </div>
                </div>
                <a
                  className="cards__item__link link-complejo"
                  target="_blank"
                  href={`https://api.whatsapp.com/send?phone=5491139167902&text=Hola,%20quisiera%20realizar%20una%20consulta%20sobre%20el%20complejo%20de%20cabañas.`}
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-whatsapp fa-lg"></i>&nbsp;CONSULTAR
                </a>
              </div>
            </div>
          </div>
        </div>
        <h2 className="cardLine linea-clasica">
          LÍNEA CLÁSICA
          <div className="rectangulo"></div>
        </h2>
        <p className="cardLineSubText">¡Armá tu plan de financiación ideal!</p>
        <ul className="cards__items">
          {items_1.map((item, i) => (
            <CardItem
              key={i}
              src={item.src}
              text={item.text}
              label={item.label}
              path={item.path}
              camas={item.camas}
              duchas={item.duchas}
              metros_cubierto={item.metros_cubierto}
              metros_semicubierto={item.metros_semicubierto}
              entrega={item.entrega}
              cuotas={item.cuotas}
              plantas={item.plantas}
              video={item.video}
              zoom={item.zoom}
              vsrc={item.vsrc}
              contenedor={item.contenedor}
            />
          ))}
        </ul>
        <h2 className="cardLine mt linea-moderna">
          LÍNEA MODERNA
          <div className="rectangulo"></div>
        </h2>
        <p className="cardLineSubText">¡Construimos en todo el país!</p>
        <ul className="cards__items">
          {items_2.map((item, i) => (
            <CardItem
              key={i}
              src={item.src}
              text={item.text}
              label={item.label}
              path={item.path}
              camas={item.camas}
              duchas={item.duchas}
              metros_cubierto={item.metros_cubierto}
              metros_semicubierto={item.metros_semicubierto}
              entrega={item.entrega}
              cuotas={item.cuotas}
              plantas={item.plantas}
              video={item.video}
              zoom={item.zoom}
              vsrc={item.vsrc}
              contenedor={item.contenedor}
              className="card_size"
            />
          ))}
        </ul>
        <h2 className="cardLine mt linea-premium">
          LÍNEA PREMIUM
          <div className="rectangulo"></div>
        </h2>
        <p className="cardLineSubText">¡Congelá hoy el precio!</p>
        <ul className="cards__items">
          {items_3.map((item, i) => (
            <CardItem
              key={i}
              src={item.src}
              text={item.text}
              label={item.label}
              path={item.path}
              camas={item.camas}
              duchas={item.duchas}
              metros_cubierto={item.metros_cubierto}
              metros_semicubierto={item.metros_semicubierto}
              entrega={item.entrega}
              cuotas={item.cuotas}
              plantas={item.plantas}
              video={item.video}
              zoom={item.zoom}
              vsrc={item.vsrc}
              contenedor={item.contenedor}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Models;
