import React, { useEffect, useState } from "react";
import PT from "prop-types";
import "lightgallery.js/dist/css/lightgallery.css";

// iconos casa
import cama from "../../assets/images/iconos/cama.svg";
import ducha from "../../assets/images/iconos/ducha.svg";
import metro from "../../assets/images/iconos/metros.svg";

import {
  LightgalleryProvider,
  LightgalleryItem,
  useLightgallery,
} from "react-lightgallery";

const PhotoItem = ({ image, thumb, group }) => (
  <div
    style={{
      maxWidth: "220px",
      width: "200px",
      padding: "5px",
      display: "none",
    }}
  >
    <LightgalleryItem group={group} src={image} thumb={thumb}>
      <img alt="modelo" src={image} style={{ width: "100%" }} />
    </LightgalleryItem>
  </div>
);
PhotoItem.propTypes = {
  image: PT.string.isRequired,
  thumb: PT.string,
  group: PT.string.isRequired,
};

function CardItem(props) {
  const [visible, setVisible] = useState(true);
  const [sistema] = useState(true);

  var cubierto_1p = sistema ? 430000 : 0;
  var cubierto_2p = sistema ? 430000 : 0;
  var semi_1p = sistema ? 215000 : 0;
  var semi_2p = sistema ? 215000 : 0;
  var precio = 0;
  if (props.plantas === "1") {
    precio =
      props.metros_semicubierto * semi_1p + props.metros_cubierto * cubierto_1p;
  } else {
    precio =
      props.metros_semicubierto * semi_2p + props.metros_cubierto * cubierto_2p;
  }
  var total =
    parseFloat(props.metros_cubierto) + parseFloat(props.metros_semicubierto);

  var entrega = (precio * props.entrega) / "100";
  var resto_cuotas = precio - entrega;
  var precio_cuota = resto_cuotas / props.cuotas;

  var loadVideo = props.video;
  var vsrc = props.vsrc;
  var zoom = props.zoom;

  var contenedor = props.contenedor;

  const ImgElement = (props) => {
    const { openGallery } = useLightgallery();
    ImgElement.propTypes = {
      className: PT.string,
    };

    return (
      <>
        {loadVideo ? (
          <video
            height="auto"
            width="100%"
            autoPlay
            loop
            muted
            playsInline
            src={props.vsrc}
            className="cards__item__video"
            onClick={() => openGallery("MODELO1")}
          >
            <source src={vsrc} type="video/mp4" />
          </video>
        ) : zoom ? (
          <img
            alt={props.label}
            className="cards__item__zoom"
            src={props.src}
            onClick={() => openGallery("MODELO1")}
          />
        ) : (
          <>
            {contenedor.map((item, key) => (
              <img
                className="cards__item__img"
                src={item}
                alt={props.label}
                onClick={() => openGallery("MODELO1")}
              />
            ))}
          </>
        )}
      </>
    );
  };

  return (
    <li style={{ listStyle: "none" }}>
      <div className="cards__item">
        {props.tag == "Oferta Limitada" && (
          <img className="tag" src="/Oferta_Limitada.svg" alt="" />
        )}
        {props.tag == "Promo Imperdible" && (
          <img className="tag" src="/Promo_Imperdible.svg" alt="" />
        )}
        {props.tag == "Precio Imperdible" && (
          <img className="tag" src="/Precio_Increíble.svg" alt="" />
        )}
        <div className="cards__item__pic-wrap">
          <LightgalleryProvider>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {contenedor.map((p, idx) => (
                <PhotoItem key={idx} image={p} group={"MODELO1"} />
              ))}
            </div>
            <ImgElement src={props.src} />
          </LightgalleryProvider>
        </div>
        <div className="cards__item__info">
          <div className="cards__item__display">
            <p className="cards__title__model">{props.label}</p>
            <div className="cards__item__display_icon">
              <div className="cards__item__data">
                <img
                  src={cama}
                  alt="Dormitorios"
                  className="cards__item__data__icon"
                />
                <p className="cards__item__data__text">{props.camas}</p>
              </div>
              <div className="cards__item__data">
                <img
                  src={ducha}
                  alt="Baños"
                  className="cards__item__data__icon"
                />
                <p className="cards__item__data__text">{props.duchas}</p>
              </div>
              <div className="cards__item__data">
                <img
                  src={metro}
                  alt="Metros"
                  className="cards__item__data__icon"
                />
                <p className="cards__item__data__text">
                  {number_format(total, 2)} m²
                </p>
              </div>
            </div>
          </div>
          <hr className="cards__item__line"></hr>
          <div className="cards__item__prince">
            <div
              className={
                sistema
                  ? "cards__item__info__prince"
                  : "cards__item__info__prince cards__item__info__prince--steel"
              }
            >
              <h6 className="cards__item__entrega">
                Entrega{" "}
                <strong className="">
                  ${number_format(entrega, 0, ",", ".")}
                </strong>
              </h6>
              <h5 className="cards__item__cuotas">
                + cuotas ${number_format(precio_cuota, 0, ",", ".")}
              </h5>
            </div>
            {visible ? (
              <LightgalleryProvider>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {props.contenedor.reverse().map((p, idx) => (
                    <PhotoItem key={idx} image={p} group={"MODELO1"} />
                  ))}
                </div>
                <a
                  className="cards__item__link"
                  target="_blank"
                  href={`https://api.whatsapp.com/send?phone=5491139167902&text=Hola,%20quisiera%20realizar%20una%20consulta%20sobre%20el%20${props.label}.`}
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-whatsapp fa-lg"></i>&nbsp;CONSULTAR
                </a>
              </LightgalleryProvider>
            ) : null}
          </div>
          <hr className="cards__item__line"></hr>
          <div className="cards__item__prince">
            <div className="cards__item__prince__item">
              <img src="/Icono_Garantía.svg" alt="Compra protegida" />
              <span>
                Entrega garantizada
                <br /> a partir de los 90 días. **
              </span>
            </div>
            <div className="cards__item__prince__item">
              <img src="/Icono_Compra_Protegida.svg" alt="Compra protegida" />
              <span>Compra protegida.</span>
            </div>
          </div>
          <div style={{ marginTop: "5px" }}>
            <small style={{ color: "white", fontSize: "9px", opacity: ".8" }}>
              (*) Las imágenes son de carácter ilustrativo. No representan el
              producto final.
            </small>
            <br />
            <small style={{ color: "white", fontSize: "9px", opacity: ".8" }}>
              (**) Sujeto a tamaño de proyecto y cumplimiento de condiciones
              contractuales.
            </small>
          </div>
        </div>
      </div>
    </li>
  );
}

function number_format(number, decimals, dec_point, thousands_sep) {
  // Strip all characters but numerical ones.
  number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
    dec = typeof dec_point === "undefined" ? "." : dec_point,
    s = "",
    toFixedFix = function(n, prec) {
      var k = Math.pow(10, prec);
      return "" + Math.round(n * k) / k;
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
}

export default CardItem;
